
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Company } from '../../entities/company.entity';
import companyService from '../../services/companies.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class CompanyListComponent extends Vue {
  private companies: Company[] = [];

  private headers: any = [
    { text: 'Nome', value: 'name', width: '40%' },
    { text: 'Nome Fantasia', value: 'trading_name', width: '40%' },
    {
      text: 'Ativo',
      value: 'activeText',
    },
    {
      text: 'Ações',
      align: 'center',
      value: 'actions',
    },
  ];

  private goToFormEdit(company: Company) {
    this.$router.push({
      name: 'company-edit',
      params: {
        id: company.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'company-create',
    });
  }

  private async getCompanies() {
    const companies = await companyService.getAllCompanies();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  public async backPagination() {
    if (!companyService.simplePaginationService.prevLink) return;
    const companies = await companyService.simplePaginationService.prev();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  public async nextPagination() {
    if (!companyService.simplePaginationService.nextLink) return;
    const companies = await companyService.simplePaginationService.next();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  public async goToFirst() {
    if (!companyService.simplePaginationService.firstLink) return;
    const companies = await companyService.simplePaginationService.goToFirst();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  private async created() {
    this.getCompanies();
  }
}
